import Api from '@/services/Api'
import AuthApi from "@/services/AuthApi";

export default {
	linkDiscord(code) {
		return Api().post('/account/discord/connect', { code })
	},
	unlinkDiscord() {
		return Api().post('/account/discord/disconnect')
	},
	updateDiscord(uid) {
		return Api().post('/account/discord/update', { uid })
	},
	compareDiscord() {
		return Api().post('/account/discord/compare')
	},
	linkTelegram(telegram_id, telegram_name) {
		return Api().post('/account/telegram/link', { telegram_id, telegram_name })
	},
	getTelegramInviteLink() {
		return Api().post('/account/telegram/getLink')
	},
	getTelegramInviteLinkRektTrader() {
		return Api().post('/account/telegram/getLink/rekt')
	},
	getTelegramInviteLinkKozTrader() {
		return Api().post('/account/telegram/getLink/koz')
	},
	kickUserFromTelegram(uid, telegram_id) {
		return Api().post('/account/telegram/kick', { uid, telegram_id })
	},
	promoteUserToStaff(uid) {
		return Api().post('/account/promote/staff', { uid })
	},
	removeUserFromStaff(uid) {
		return Api().post('/account/demote/staff', { uid })
	},
	checkIfExpiredMemberIsInTelegram(chat_id, telegram_user_id) {
		return Api().post('/account/telegram/checkIfExpiredMemberIsInTelegram', { chat_id, telegram_user_id })
	},
	deleteUser(uid) {
		return Api().post('/account/delete', { uid })
	},
	createPaymentRequest() {
		return Api().get('/payments/request')
	},
	createBlockbeeAddress() {
		return Api().get('/payments/blockbee/create-address')
	},
	createBlockbeeAddressTrc() {
		return Api().get('/payments/blockbee/create-address/trc20')
	},
	createBlockbeeAddressBep() {
		return Api().get('/payments/blockbee/create-address/bep20')
	},
	createBlockbeeAddressPolygon() {
		return Api().get('/payments/blockbee/create-address/polygon')
	},
	createUsdtPaymentRequest(amount) {
		return Api().post('/payments/request/usdt', { amount })
	},
	getUsdtInvoice(id) {
		return Api().post('/payments/request/usdt/invoice', { id })
	},
	createInvoiceRequest() {
		return Api().get('/payments/create-invoice')
	},
	activateSubscription(plan) {
		return Api().post('/account/activate', { plan })
	},
	register(code) {
		return AuthApi().post('/account/register', { code })
	},
	createAccount(code) {
		return Api().post('/account/create', { code })
	},
	deleteInactiveAccounts() {
		return Api().post('/account/purge/inactive')
	},
	deleteExpiredAccounts() {
		return Api().post('/account/purge/expired')
	},
}
