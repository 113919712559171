import Firebase from 'firebase'
import 'firebase/firestore'

let config = {
	apiKey: "AIzaSyCM_aMZANFESEIm9h7i9Mh8muSuGcvac3I",
	authDomain: "rektproof-pa.firebaseapp.com",
	databaseURL: "https://rektproof-pa.firebaseio.com",
	projectId: "rektproof-pa",
	storageBucket: "rektproof-pa.appspot.com",
	messagingSenderId: "960425227421",
	appId: "1:960425227421:web:43f6054e20e5c12fda9074",
	measurementId: "G-2996488VBE"
};

Firebase.initializeApp(config);

const emulator = process.env.VUE_APP_FIRESTORE_EMULATOR;

// export default Firebase.firestore(); utils
const db = Firebase.firestore()
const auth = Firebase.auth()
const functions = Firebase.functions()

if (emulator === "true") {
	db.useEmulator('localhost', 8082);
	functions.useEmulator('localhost', 5001);
}

// collection references
const usersCollection = db.collection('users')

// export utils/refs
export {
	db,
	auth,
	functions,
	usersCollection,
}
