import axios from "axios";
import { auth } from "@/firebase"

const API_URL = process.env.VUE_APP_API_URL;

export default () => {
    // const firebaseIdToken = await auth.currentUser.getIdToken()
    // console.log(firebaseIdToken)
    const axiosInstance = axios.create({
        baseURL: API_URL,
        headers: {
            "Content-Type": "application/json",
            'Access-Control-Allow-Origin': '*',
            // 'token': firebaseIdToken
        }
    });
    // Request interceptor for API calls
    axiosInstance.interceptors.request.use(
        async config => {
            const firebaseIdToken = await auth.currentUser.getIdToken()
            // console.log(firebaseIdToken)
            config.headers = {
                'token': firebaseIdToken
            }
            return config;
        },
        error => {
            Promise.reject(error)
        });
    return axiosInstance
};
