import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { auth } from '@/firebase'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { firestorePlugin } from 'vuefire'
import VueApexCharts from 'vue-apexcharts'

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

require('@/assets/main.scss');

Vue.config.productionTip = false
Vue.use(VueAxios, axios)
Vue.use(firestorePlugin)
Vue.use(VueApexCharts)

let app
auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})
