import Vue from 'vue'
import VueRouter from 'vue-router'
import { auth } from '@/firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/home2',
    name: 'Home2',
    component: () => import( /* webpackChunkName: "home" */ '../views/Home2.vue')
  },
  {
    path: '/materials',
    name: 'HomeMaterials',
    component: () => import( /* webpackChunkName: "home" */ '../views/HomeMaterials.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import( /* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import( /* webpackChunkName: "login" */ '../views/Register.vue')
  },
  {
    path: '/waitlist',
    name: 'Waitlist',
    component: () => import( /* webpackChunkName: "waitlist" */ '../views/Waitlist.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/pdf-lessons',
    name: 'EducationPDFLessons',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/EducationPDFLessons.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/1',
    name: 'Lesson1',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson1.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/2',
    name: 'Lesson2',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson2.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/3',
    name: 'Lesson3',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson3.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/4',
    name: 'Lesson4',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson4.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/5',
    name: 'Lesson5',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson5.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/6',
    name: 'Lesson6',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson6.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/7',
    name: 'Lesson7',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson7.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/8',
    name: 'Lesson8',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson8.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/9',
    name: 'Lesson9',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson9.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/10',
    name: 'Lesson10',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson10.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/11',
    name: 'Lesson11',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson11.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/lessons/12',
    name: 'Lesson12',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/lessons/Lesson12.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/add-lesson/pdf',
    name: 'EducationPDFLessonsAddLesson',
    component: () => import(/* webpackChunkName: "pdf-lessons-add" */ '../views/EducationPDFLessonsAddLesson.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/pdf-lessons/:lesson',
    name: 'EducationPDFLessonsLesson',
    component: () => import(/* webpackChunkName: "pdf-lesson" */ '../views/EducationPDFLessonsLesson.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/videos',
    name: 'KozHubVideos',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/KozHubVideos.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/videos/add-video',
    name: 'KozHubVideosAddVideo',
    component: () => import(/* webpackChunkName: "pdf-lessons" */ '../views/KozHubVideosAddVideo.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/videos/:video',
    name: 'KozHubVideosVideo',
    component: () => import(/* webpackChunkName: "pdf-lesson" */ '../views/KozHubVideosVideo.vue'),
    meta: {
      requiresAuth: true
    }
  },
  // {
  //   path: '/education/video-lessons',
  //   name: 'EducationVideoLessons',
  //   component: () => import(/* webpackChunkName: "video-lessons" */ '../views/EducationVideoLessons.vue'),
  //   meta: {
  //     requiresAuth: true
  //   }
  // },
  {
    path: '/education/exams',
    name: 'EducationExams',
    component: () => import(/* webpackChunkName: "exams" */ '../views/EducationExams.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/exams/:exam',
    name: 'EducationExamsExam',
    component: () => import(/* webpackChunkName: "exam" */ '../views/EducationExamsExam.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/exams/:exam/results',
    name: 'EducationExamsExamResults',
    component: () => import(/* webpackChunkName: "exam" */ '../views/EducationExamsExamResults.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/education/add-exam',
    name: 'EducationExamsAddExam',
    component: () => import(/* webpackChunkName: "exams-add" */ '../views/EducationExamsAddExam.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/membership/plans',
    name: 'MembershipPlans',
    component: () => import(/* webpackChunkName: "plans" */ '../views/MembershipPlans.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/membership/members',
    name: 'MembershipMembers',
    component: () => import(/* webpackChunkName: "members" */ '../views/MembershipMembers.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/membership/waitlist',
    name: 'MembershipWaitlist',
    component: () => import(/* webpackChunkName: "waitlist-admin" */ '../views/MembershipWaitlist.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/staff',
    name: 'SettingsStaff',
    component: () => import(/* webpackChunkName: "plans" */ '../views/SettingsStaff'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/waitlist',
    name: 'SettingsWaitlist',
    component: () => import(/* webpackChunkName: "plans" */ '../views/SettingsWaitlist'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/members',
    name: 'SettingsMembers',
    component: () => import(/* webpackChunkName: "plans" */ '../views/SettingsMembers'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/settings/discount',
    name: 'SettingsDiscount',
    component: () => import(/* webpackChunkName: "plans" */ '../views/SettingsDiscount'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/payments/btc',
    name: 'TransactionsPayments',
    component: () => import(/* webpackChunkName: "payments" */ '../views/TransactionsPayments.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/payments/usdt',
    name: 'TransactionsPaymentsUSDT',
    component: () => import(/* webpackChunkName: "payments" */ '../views/TransactionsPaymentsUSDT.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/transactions/payments/usdt_fix',
    name: 'TransactionsPaymentsUSDTFix',
    component: () => import(/* webpackChunkName: "payments" */ '../views/TransactionsPaymentsUSDTFix.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'AccountMyAccount',
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountMyAccount.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/account2',
    name: 'AccountMyAccount2',
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountMyAccount2.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/trade-journal',
    name: 'AccountMyTradeJournals',
    component: () => import(/* webpackChunkName: "trade-journals" */ '../views/AccountMyTradeJournals.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/create-lesson',
    name: 'createLesson',
    component: () => import(/* webpackChunkName: "create-lesson" */ '../views/createLesson.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/trade-journal/:journal',
    name: 'AccountMyTradeJournalsJournal',
    component: () => import(/* webpackChunkName: "trade-journal" */ '../views/AccountMyTradeJournalsJournal.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notes',
    name: 'AccountMyNotes',
    component: () => import(/* webpackChunkName: "notes" */ '../views/AccountMyNotes.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/add-note',
    name: 'AccountMyNotesAddNote',
    component: () => import(/* webpackChunkName: "notes-add" */ '../views/AccountMyNotesAddNote'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/notes/:note',
    name: 'AccountMyNotesNote',
    component: () => import(/* webpackChunkName: "pdf-lesson" */ '../views/AccountMyNotesNote.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculators',
    name: 'AccountCalculators',
    component: () => import(/* webpackChunkName: "AccountCalculators" */ '../views/AccountCalculators.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculators/ftx',
    name: 'AccountCalculatorsFTX',
    component: () => import(/* webpackChunkName: "AccountCalculatorsFTX" */ '../views/AccountCalculatorsFTX.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/calculators/position',
    name: 'AccountCalculatorsRR',
    component: () => import(/* webpackChunkName: "AccountCalculatorsRR" */ '../views/AccountCalculatorsRR.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  linkActiveClass: 'is-active',
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router
